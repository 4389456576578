<template>
  <!-- 系统演示部分 -->
  <div class="systemShow">
    <nav class="navBar">
      <ul>
        <li><a href="#">硬件演示</a></li>
        <li><a href="#">调试演示</a></li>
        <li><a href="#">使用演示</a></li>
      </ul>
    </nav>
    <div class="videoShow">
      <!-- <video width="320" height="240">
                <source src="movie.mp4" type="video/mp4">
            </video> -->
      <!-- <img src="./images/9.png" alt="" />
      <img src="./images/9.png" alt="" />
      <img src="./images/9.png" alt="" />
      <img src="./images/9.png" alt="" />
      <img src="./images/9.png" alt="" />
      <img src="./images/9.png" alt="" />
      <img src="./images/9.png" alt="" />
      <img src="./images/9.png" alt="" /> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scope>
.systemShow {
  margin: 70px auto;
  width: 1200px;
  height: 900px;
}
.navBar ul {
  display: flex;
  justify-content: center;
}
.navBar ul li {
  font-size: 200;
  width: 200px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border-left: 1px solid #ccc;
}
.navBar ul li a {
  color: #000;
}
.navBar ul :first-child {
  border-left: none;
}
.systemShow .videoShow {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: space-around;
  margin-top: 100px;
  height: 700px;
}
</style>